import React, { useState } from 'react';
import './Habibi.css';

export default function Habibi() {
  const [poemIndex, setPoemIndex] = useState(-1);
  const [fade, setFade] = useState(false);
  const poem = 'Sometimes in the darkness of the night\nI look at the stars - wondering\nIf one day I\'ll understand their mysteries\nFunny thing is - you\'ve got everything of a star\nYou shine\nYou amaze\nYou\'re beautiful\nYet there\'s a mystery about you that I understand\nHow I fell in love with you\nI swear you\'re an angel';
  const poemArray = poem.split('\n');

  const nextLine = () => {
    if (poemIndex === poemArray.length - 1) {
      setPoemIndex(-1);
      return;
    }
    setPoemIndex(poemIndex + 1);
    return;
  };

  return (
    <div className={`main-container${poemIndex === poemArray.length - 1 ? ' revealed' : ''}`}>
      <button
        type="button"
        className={
          `btn${poemIndex === poemArray.length - 1 ? ' btn-revealed' : ''}${poemIndex === -1 ? ' btn-shine' : ''}${fade ? ' fade' : ''}`
        }
        onClick={() => {
          setFade(true);
          nextLine();
        }}
        onAnimationEnd={() => setFade(false)}
      >
        {poemIndex > -1 ? poemArray[poemIndex] : 'Habibi'}
      </button>
    </div>
  );
};

// Hey sneaky lad, here is the poem ;)
// ---
// Sometimes in the darkness of the night
// I look at the stars - wondering
// If one day I'll understand their mysteries
// Funny thing is - you've got everything of a star
// You shine
// You amaze
// You're beautiful
// Yet there's a mystery about you that I understand
// How I fell in love with you
// I swear you're an angel
